import * as React from 'react'
import * as Api from '../api/api'
import {EditFormBase} from "../forms";
import Page from "../components/Page";
import IndexLayout from "../layouts";

export class Thanks extends React.Component<{}, Api.DirectAlertTableEntity & {isLoading: boolean}> {
	constructor(props, context) {
		super(props, context);
		this.state = {isLoading: false};
	}

	public render() {
		return  (
			<IndexLayout>
				<Page>
					{this.renderInside()}
				</Page>
			</IndexLayout>
		);
	}

	public renderInside() {
		return (
			<main>
				<h1>Thanks!</h1>
				<p>Your contact information has been updated successfully.</p>
			</main>
		);
	}
}

export default Thanks;
